Vue.component('shortlist-button', {
    template: '#shortlist-button',
    props: {
        barrister: {
            type: Object,
        },
    },
    data() {
        return {
            shortlisted: false,
        };
    },
    created() {
        let currentShortlisted = window.localStorage.getItem('shortlistedBarristers');
        if (currentShortlisted !== null) {
            this.shortlisted = !! JSON.parse(currentShortlisted).find(shortlist => shortlist === this.barrister.ID);
        }
    },
    methods: {
        shortlist(id) {
            // Get all storage values
            let currentShortlisted = window.localStorage.getItem('shortlistedBarristers');
            // Convert to array
            if (currentShortlisted === null) {
                currentShortlisted = "[]";
            }
            currentShortlisted = JSON.parse(currentShortlisted);
            // If it is not currently shortlisted, add to shortlist.
            if (!this.shortlisted) {
                currentShortlisted.push(id);
                this.shortlisted = true;
            } else {
                currentShortlisted.splice(currentShortlisted.indexOf(id), 1);
                this.shortlisted = false;
            }

            // Convert array to string
            currentShortlisted = JSON.stringify(currentShortlisted);
            // Store again
            window.localStorage.setItem('shortlistedBarristers', currentShortlisted);
        },
    }
});

Vue.component('barrister-card', {
    template: '#barrister-card',
    props: {
        barrister: {
            type: Object,
        },
    },
    data() {
        return {
            shortlisted: false,
        };
    },
    created() {
        let currentShortlisted = window.localStorage.getItem('shortlistedBarristers');
        if (currentShortlisted !== null) {
            this.shortlisted = !! JSON.parse(currentShortlisted).find(shortlist => shortlist === this.barrister.ID);
        }
    },
    methods: {
        shortlist(id) {
            // Get all storage values
            let currentShortlisted = window.localStorage.getItem('shortlistedBarristers');
            // Convert to array
            if (currentShortlisted === null) {
                currentShortlisted = "[]";
            }
            currentShortlisted = JSON.parse(currentShortlisted);
            // If it is not currently shortlisted, add to shortlist.
            if (!this.shortlisted) {
                currentShortlisted.push(id);
                this.shortlisted = true;
            } else {
                currentShortlisted.splice(currentShortlisted.indexOf(id), 1);
                this.shortlisted = false;
            }

            // Convert array to string
            currentShortlisted = JSON.stringify(currentShortlisted);
            // Store again
            window.localStorage.setItem('shortlistedBarristers', currentShortlisted);
        },
    }
});

Vue.component('testimonial-card', {
    template: '#testimonial-card',
    props: {
        testimonials: [Object, Array],
        testimonial: [Object, Array],
        showresult: Boolean,
        showloading: Boolean,
        layout: String
    }
});

Vue.component('imageFade', {
    props: {
        src: String,
        className: {
            type: String,
            default: 'barrister-card__wrapper-img'
        },
        listSrc: Array,
        pagination: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            loaded: false,
            currentImg: 0,
            imgsrc: this.src
        }
    },
    methods: {
        onLoaded() {
            this.loaded = true;
        },
        paginateImgs(action = 'next') {
            const self = this;
            this.loaded = false;
            let listSrcLength = this.listSrc.length,
                renderImg = new Image();
            if (action == 'prev') {
                if (this.currentImg == 0) {
                    this.currentImg = listSrcLength - 1
                } else {
                    this.currentImg = this.currentImg - 1
                }
            } else {
                if ((this.currentImg + 1) == listSrcLength) {
                    this.currentImg = 0
                    this.imgsrc = this.src
                    this.loaded = true
                    return
                } else {
                    this.currentImg = this.currentImg + 1
                }
            }
            renderImg.src = this.listSrc[this.currentImg]
            renderImg.onload = function() {
                self.imgsrc = renderImg.src
                self.loaded = true;
            }
        }
    },
    template: `
      <div style="height: 100%; line-height: 0">
        <div class="card-loader__image position-relative" :class="className" style="min-height: 100%" v-if="!loaded"></div>
        <transition name="fade" :duration="{leave: 0 }">
          <img :class="className" :src="imgsrc" @load="onLoaded" v-show="loaded">
        </transition>
      </div>
  `
});
